import "./App.css";
import { Fragment, useEffect } from "react";
import { ThemeProvider, Box } from "@mui/material";
import theme from "./theme";
import { Authentication, Main } from "./pages";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider, ToastProvider } from "./contexts";

function App() {
  useEffect(() => {
    console.log("🚀 ~ App version 1.0.0.1 (1) (03 Oct 2024)");
  }, []);

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            bgcolor: theme.palette.common.greyBg,
            minHeight: "100vh",
          }}
        >
          <AuthProvider>
            <ToastProvider>
              <Router>
                <Authentication />
                <Main />
              </Router>
            </ToastProvider>
          </AuthProvider>
        </Box>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
